import React, { useEffect, useMemo, useState } from 'react';

import Section from '../../design_system/section/section';
import SectionTitle from '../../design_system/section/section_title/section_title';

import legalMentionsData from './legal_mentions_data';

import classes from './legal_mentions_content.module.css';

const LegalMentionsContent = () => {
	const [dismissUnderline, setDismissUnderline] = useState(false);
	useEffect(() => setDismissUnderline(true), []);
	return (
		<Section withClassName={classes.container}>
			<SectionTitle
				className={classes.title}
				underlineClassName={classes.underline}
				{...{ dismissUnderline }}
			>
				{'Mentions légales'}
			</SectionTitle>
			<div className={classes.content}>
				<ContentTexts />
			</div>
		</Section>
	);
}

const ContentTexts = () => {
	const entries = Object.entries(legalMentionsData);
	return entries.map(([contentSectionId, { title, text }]) => (
		<div
			key={`legal_mentions_content_section_${contentSectionId}`}
			className={classes.contentSection}
		>
			<h2 className={classes.contentSectionTitle}>
				{title}
			</h2>
			<p className={classes.contentSectionText}>
				{text}
			</p>
		</div>
	))
};

export default LegalMentionsContent;

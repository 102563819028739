import React from 'react';

import HelmetLayout from '../components/layouts/helmet_layout/helmet_layout';
import MainLayout from '../components/layouts/main_layout/main_layout';
import LegalMentionsHeader from '../components/legal_mentions/legal_mentions_header/legal_mentions_header';
import LegalMentionsContent from '../components/legal_mentions/legal_mentions_content/legal_mentions_content';

const Contact = () => (
	<HelmetLayout>
		<MainLayout
			footerProps={{
				dismissedSections: [
					'create-demo-account',
					'trusting-customers-list'
				]
			}}
		>
			<LegalMentionsHeader />
			<LegalMentionsContent />
		</MainLayout>
	</HelmetLayout>
);

export default Contact;

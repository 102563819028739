const legalMentionsData = Object.freeze({
	coordonates: {
		title: 'Coordonnées',
		text: `Karnott est une marque de la SAS ConnectAgri.
Le site internet www.karnott.fr est la propriété de : SAS CONNECTAGRI - 225 rue des Templiers - 59000 Lille
Directeurs de la publication : Alexandre CUVELIER & Antoine DEQUIDT`
	},
	legalInformations: {
		title: 'Informations Légales et libertés individuelles',
		text: `Conformément à la loi 78-17 du 6 janvier 1978 modifiée, relative à l’informatique, aux fichiers et aux libertés, ce site a fait l’objet d’une déclaration simplifiée numéro 48 auprès de la Commission Nationale de l’Informatique et des Libertés (CNIL) sous le numéro 2084856 v 0.
Au cours de votre visite sur ce site, il pourra vous être demandé de saisir des informations personnelles nominatives vous concernant (nom, numéro de téléphone, adresse …). Nous vous informons que ces données recueillies par l’intermédiaire d’un formulaire ou autre ne sauraient, en aucun cas, être transmises, à titre gratuit ou onéreux, à des tiers, personnes physiques ou morales. Ces informations nous permettent notamment de mieux vous connaître, de répondre à vos besoins et de vous dispenser un service de qualité.`
	},
	accessRights: {
		title: 'Droits d’accès, de rectifiication et de suppression',
		text:
      'En application de l’article 27 de la loi n°78-17 du 6 janvier 1978 relative à l’informatique, aux fichiers et aux libertés, vous disposez d’un droit d’accès, de rectification, de modification et de suppression concernant les données qui vous concernent. Vous pouvez exercer ce droit en envoyant un mail au webmaster.'
	},
	accessRights2: {
		title: 'Droits d’accès, de rectifiication et de suppression',
		text: `Tous les éléments de ce site Internet sont la propriété des éditeurs de ce site, à l’exclusion des éléments émanant de partenaires.
La protection du site dmindustries.fr relève, par conséquent, des législations nationales et internationales relatives au droit de la propriété intellectuelle.
Dès lors, toute reproduction et/ou représentation et/ou rediffusion, en tout ou partie, sur tout support électronique ou non, présent ou futur, sont interdites sauf autorisation expresse et préalable.
De même, les bases de données d’informations sont protégées au titre du droit applicable aux compilations de données. De sorte que toute extraction ou tentative d’extraction, fut-elle totale ou partielle, est susceptible d’engager les responsabilités civile et pénale de tout contrevenant.`
	},
	hyperlinks: {
		title: 'Mise en place des liens hypertextes',
		text: `Nous vous informons que karnott.fr autorise tout site Internet ou tout autre support à citer le site ou à mettre en place un lien hypertexte pointant vers l’adresse karnott.fr.
L’autorisation de mise en place d’un lien est valable pour tout support, à l’exception de ceux pouvant porter atteinte à l’ordre public et aux bonnes mœurs. Au titre de cette autorisation, nous nous réservons un droit d’opposition.`
	},
	credits: {
		title: 'Crédits',
		text: `Sources photographiques : Unsplash /… / … / … / …
Conception graphique et réalisation du site : © 2019 Karnott,  tout droits réservés.`
	}
})

export default legalMentionsData
